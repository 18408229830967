/*//=============================== CUSTOM STYLES =======================*/
.flex-column-fluid {
    flex: 1 0 auto;
}
.bg-white {
    background-color: #ffffff !important;
}
.bg-gray {
    background-color: #eeeeee !important;
}
.bgi-no-repeat {
    background-repeat: no-repeat;
}
.bgi-size-cover {
    background-size: cover;
}
.login-aside {
    max-width: 450px;
}
a {
    text-decoration: none;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: #1a90d2 !important;
}
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background: #1a90d2 !important;
}
.application-title {
    background: #104f67 !important;
    color: #fff;
    border-bottom: 1px solid #d4d9df;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0.625rem;
    height: 60px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-form {
    width: 100%;
    max-width: 400px;
}

.flex-row-fluid {
    flex: 1 auto;
    -ms-flex: 1 0 0px;
}

.flex-column-fluid {
    -webkit-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
}

.is-valid {
    border-color: #13f108;
}

.is-invalid {
    border-color: #f80810;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #3f4254;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px 20px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.btn-primary {
    color: #fff !important;
    background-color: #3699ff !important;
    border-color: #3699ff !important;
    box-shadow: none !important;
}

.btn-secondary {
    color: #fff !important;
    background-color: #031f6c !important;
    border-color: #031f6c !important;
    box-shadow: none !important;
}

.mx-2{
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.multi-select {
    max-width: 415px !important;
}

label {
    font-weight: bold !important;
    font-size: 16px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiDataGrid-cell--withRenderer{
    align-items: start !important;
}

.ck-content {
    height: 300px !important;
    overflow: auto !important;
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }
}

@media (max-width: 640px) {
    .multi-select {
        max-width: 241px !important;
    }
}


::-webkit-scrollbar {
    width: .55em;
    height: .6em;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: linear-gradient(to top ,#88e0ab,#b1e0c5);
  }


  /* style leads page table */
.yellow{
    background-color: rgb(245, 245, 83);
}
.green{
    background-color: rgb(39, 240, 39);
}
.red{
    background-color: rgb(249, 89, 89);
}

.parser p {
    display: block;
    margin: 0;
}